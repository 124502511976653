<template>
	<div v-if="appointments.length" class="products-catalog">
		<div class="products-catalog__container">
			<table style="width: 100%">
				<thead class="products-catalog__header">
					<tr>
						<th class="products-catalog__header-text" style="width: 5%"></th>
						<th class="products-catalog__header-text" style="width: 27%; cursor: pointer">
							{{ $t('appointments.appointmentTabExpositor') }}
						</th>
						<th class="products-catalog__header-text" style="width: 20%; cursor: pointer">{{ $t('appointments.appointmentTabDate') }}</th>
						<th class="products-catalog__header-text" style="width: 18%; cursor: pointer">
							{{ $t('appointments.appointmentTabsStatus') }}
						</th>
						<th class="products-catalog__header-text" style="width: 15%; cursor: pointer">
							{{ $t('appointments.appointmentTabMeeting') }}
						</th>
						<th class="products-catalog__header-text" style="width: 15%; cursor: pointer"></th>
					</tr>
				</thead>
				<tbody>
					<tr
						v-for="item in appointments"
						:key="item.appoid"
						@click="
							ItemSelect = item;
							getItemInfo();
						"
					>
						<td class="products-catalog__body-row-sector" @click="dialog = true"></td>
						<td class="products-catalog__body-row-sector" @click="dialog = true">{{ item.exhibitorname }}</td>
						<td class="products-catalog__body-row-sector" @click="dialog = true">{{ transformDate(item.appodate) }}</td>
						<td class="products-catalog__body-row-status" @click="dialog = true">
							<div style="display: flex" v-if="item.appostatus == 'ACCEPTED'">
								<div class="status-accepted">
									{{ $t('appointments.appointmentAccepted') }}
								</div>
							</div>
							<div style="display: flex" v-else-if="item.appostatus == 'PENDING_EXHIBITOR'">
								<div class="status-progress" style="background: #d4af37">
									{{ $t('appointments.appointmentTabPendingExhibitor') }}
								</div>
							</div>
							<div style="display: flex" v-else-if="item.appostatus == 'PENDING_VISITOR'">
								<div class="status-progress">
									{{ $t('appointments.appointmentTabPendingVisitor') }}
								</div>
							</div>
							<div style="display: flex" v-else>
								<div class="status-denied">
									{{ $t('appointments.appointmentRejected') }}
								</div>
							</div>
						</td>
						<td class="products-catalog__body-row-sector" @click="dialog = true">{{ item.appotype }}</td>
						<td class="products-catalog__body-row-sector">
							<div v-if="item.appourl && item.appotype.toLowerCase() === 'online' && item.appostatus == 'ACCEPTED'">
								<v-btn class="appointments-meeting-link" color="#2498FF" depressed>
									<a class="appointments-meeting-link" :href="item.appourl" color="white" target="_blank" rel="noopener noreferrer">
										{{ $t('appointments.enterTheMeeting') }}
									</a></v-btn
								>
							</div>
						</td>
					</tr>
				</tbody>

				<v-dialog v-model="dialog" width="700">
					<v-card>
						<div style="position: relative">
							<div style="position: absolute; right: 0; margin-right: 1rem; margin-top: 1rem">
								<div class="exhibitor-details-products__item-top-image-buttons">
									<img class="close" style="cursor: pointer" @click="dialog = false" :src="IconClose" alt="favorite" />
								</div>
							</div>
						</div>
						<v-card-title class="text-h5"> {{ $t('appointments.appointmentDate') }} </v-card-title>
						<v-container fluid style="padding-left: 24px">
							<v-row align="center">
								<v-col class="d-flex" cols="12" sm="8">
									<pui-date-field
										disabled
										v-model="ItemSelect.appodate"
										:label="$t('appointments.appointmentTabDate')"
										:color="primaryColor"
										toplabel
										time
									></pui-date-field>
								</v-col>

								<v-col v-if="ItemSelectType !== null" class="d-flex" cols="12" sm="4">
									<v-text-field :label="$t('appointments.appointmentType')" readonly :value="ItemSelectType"></v-text-field>
								</v-col>
							</v-row>
						</v-container>

						<div v-if="ItemSelect.appostatus == 'PENDING_VISITOR'" class="appointments_container">
							<div class="appointments_container_alert">
								<div class="appointments_container_alert-top">
									<img class="appointments_container_alert-bell" :src="IconAlert" alt="alert" />
									<div class="appointments_container_alert-hour">
										{{ $t('appointments.appointmentTimeChangued') }}
									</div>
								</div>
								<div class="appointments_container-subtitle-left">{{ getItemSelectDate(ItemSelect.appodate) }}</div>
							</div>

							<div class="appointments_container-buttons">
								<v-btn @click="acceptAppointment()" class="appointments_container-buttons-right-accept" color="#2498FF" depressed>
									{{ $t('filters.modalSubmit') }}
								</v-btn>
								<v-btn @click="rejectAppointment()" class="appointments_container-buttons-right-deny" color="#FFFFFF" depressed>
									{{ $t('filters.modalReject') }}
								</v-btn>
							</div>
						</div>
						<div
							v-if="(ItemSelectInfo && ItemSelectInfo.appoexhibitorobs) || (ItemSelectInfo && ItemSelectInfo.appovisitorobs)"
							class="appointments-container-observations"
						>
							{{ $t('appointments.appointmentObservations') }}
						</div>
						<div
							v-if="(ItemSelectInfo && ItemSelectInfo.appoexhibitorobs) || (ItemSelectInfo && ItemSelectInfo.appovisitorobs)"
							class="appointments-container-observations-main"
						>
							<div v-if="ItemSelectInfo && ItemSelectInfo.appoexhibitorobs" class="appointments-container-observations-main-chat-left">
								<div class="appointments-container-observations-main-chat-left-top">
									<img class="appointments-container-observations-main-chat-left-icon" :src="IconUser" alt="alert" />
									<div class="appointments-container-observations-main-chat-left-text">
										{{ ItemSelect.exhibitorname }}
									</div>
								</div>
								<div class="appointments_container-subtitle-left">
									{{ ItemSelectInfo.appoexhibitorobs }}
								</div>
							</div>

							<div v-if="ItemSelectInfo && ItemSelectInfo.appovisitorobs" class="appointments-container-observations-main-chat-rigth">
								<div class="appointments-container-observations-main-chat-rigth-top">
									<div class="appointments-container-observations-main-chat-rigth-text">
										{{ user.name }} {{ $t('appointments.appointmentMe') }}
									</div>
									<img class="appointments-container-observations-main-chat-rigth-icon" :src="IconUser" alt="alert" />
								</div>
								<div class="appointments_container-subtitle-right">
									{{ ItemSelectInfo.appovisitorobs }}
								</div>
							</div>
						</div>

						<v-card-actions>
							<v-spacer></v-spacer>

							<v-btn class="appointments_container-buttons-right-accept" @click="dialog = false" color="#2498FF" depressed>
								{{ $t('login.cancel') }}
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
			</table>
		</div>
	</div>
	<AppointmentsEmpy v-else all />
</template>

<script>
import { getAppointment, acceptAppointment, rejectAppointment } from '@/api/appointments.js';
import AppointmentsEmpy from '@/components/appointments/AppointmentsEmpy.vue';

export default {
	name: 'AppointmentsTabsAll',
	props: {
		appointments: {},
		user: {}
	},
	data() {
		return {
			IconAlert: require('@/assets/icons/alert.svg'),
			IconUser: require('@/assets/icons/user.svg'),
			showProgress: false,
			dialog: false,
			items: ['Foo', 'Bar', 'Fizz', 'Buzz'],
			IconClose: require('@/assets/icons/close.svg'),
			ItemSelect: {},
			ItemSelectDate: null,
			ItemSelectTime: null,
			ItemSelectType: null,
			ItemSelectInfo: null,
			dateformated: null,
			dateformatedItemSelected: null
		};
	},
	components: { AppointmentsEmpy },
	mounted() {},
	created() {
		this.subscribeToEvents();
	},
	methods: {
		subscribeToEvents() {
			this.$puiEvents.$on('searching-true', () => {
				this.showProgress = true;
			});
			this.$puiEvents.$on('searching-false', () => {
				this.showProgress = false;
			});
		},
		transformDate(date) {
			var appointmentNewTimeFrom = `${this.$t('appointments.appointmentNewTimeFrom')}`;
			var appointmentNewTimeAt = `${this.$t('appointments.appointmentNewTimeAt')}`;
			var appointmentNewTimeAtAs = `${this.$t('appointments.appointmentNewTimeAtAs')}`;

			var dateformated = new Date(date);
			var year = dateformated.getFullYear();
			var month = dateformated.getMonth() + 1;
			var dt = dateformated.getDate();
			var hours = dateformated.getHours();
			var minutes = dateformated.getMinutes();
			if (dt < 10) {
				dt = '0' + dt;
			}
			if (month < 10) {
				month = '0' + month;
			}
			if (hours < 10) {
				hours = '0' + hours;
			}
			if (minutes < 10) {
				minutes = '0' + minutes;
			}
			return dt + appointmentNewTimeFrom + month + appointmentNewTimeAt + year + appointmentNewTimeAtAs + hours + ':' + minutes + 'h';
		},

		getItemInfo: async function () {
			this.ItemSelectType = this.ItemSelect.appotype;

			this.ItemSelectInfo = await getAppointment(this.ItemSelect.appoid);
		},
		getItemSelectDate(date) {
			var appointmentNewTime = `${this.$t('appointments.appointmentNewTime')}`;
			var appointmentNewTimeOf = `${this.$t('appointments.appointmentNewTimeOf')}`;
			var appointmentNewTimeFrom = `${this.$t('appointments.appointmentNewTimeFrom')}`;

			var dateformatedItemSelected = new Date(date);
			var year = dateformatedItemSelected.getFullYear();
			var month = dateformatedItemSelected.getMonth() + 1;
			var dt = dateformatedItemSelected.getDate();
			var hours = dateformatedItemSelected.getHours();
			var minutes = dateformatedItemSelected.getMinutes();
			if (dt < 10) {
				dt = '0' + dt;
			}
			if (month < 10) {
				month = '0' + month;
			}
			if (hours < 10) {
				hours = '0' + hours;
			}
			if (minutes < 10) {
				minutes = '0' + minutes;
			}

			return appointmentNewTime + hours + ':' + minutes + 'h' + appointmentNewTimeOf + dt + appointmentNewTimeFrom + month + ' ' + year;
		},
		acceptAppointment: async function () {
			const data = await acceptAppointment(this.ItemSelect.appoid);
			console.log(data);
			this.dialog = false;
			this.$puiEvents.$emit('reloadAppointment');
		},

		rejectAppointment: async function () {
			const data = await rejectAppointment(this.ItemSelect.appoid);
			console.log(data);
			this.dialog = false;
			this.$puiEvents.$emit('reloadAppointment');
		}
	},
	computed: {
		primaryColor: function () {
			return this.$store.state.eventInfo.evenappprimarycolor
				? this.$store.state.eventInfo.evenappprimarycolor
				: this.$store.state.eventInfo.defaultColor;
		}
	}
};
</script>


<style lang="scss" scoped>
.appointments_container {
	padding-left: 24px;
	display: flex;
}

.appointments_container_alert {
	display: flex;
	flex-direction: column;

	&-top {
		display: flex;
		align-items: center;
	}

	&-bell {
		width: 16.67px;
		height: 20px;
	}
	&-hour {
		padding-left: 4.67px;
		font-family: Roboto;
		font-style: normal;
		font-weight: bold;
		font-size: 12px;
		line-height: 15px;

		&-span {
			color: rgb(160, 159, 159);
			font-weight: normal;
		}
	}
}

.appointments-container-observations-main-chat-left {
	display: flex;
	flex-direction: column;
	margin-top: 20px;
	margin-left: 20px;

	&-top {
		display: flex;
		align-items: center;
	}

	&-icon {
		width: 16.67px;
		height: 20px;
	}
	&-text {
		padding-left: 4.67px;
		font-family: Roboto;
		font-style: normal;
		font-weight: bold;
		font-size: 12px;
		line-height: 15px;

		&-span {
			color: rgb(160, 159, 159);
			font-weight: normal;
		}
	}
}

.appointments-container-observations-main-chat-rigth {
	display: flex;
	flex-direction: column;
	margin-top: 30px;
	margin-right: 20px;
	align-items: flex-end;

	&-top {
		display: flex;
		align-items: center;
	}

	&-icon {
		width: 16.67px;
		height: 20px;
	}
	&-text {
		padding-right: 4.67px;
		font-family: Roboto;
		font-style: normal;
		font-weight: bold;
		font-size: 12px;
		line-height: 15px;

		&-span {
			color: rgb(160, 159, 159);
			font-weight: normal;
		}
	}
}
.appointments_container-subtitle {
	&-left {
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		line-height: 15px;
		/* or 125% */

		/* Neutral / Extra-dark grey */

		color: #293133;
		padding-left: 6px;
		margin-left: 25px;
		margin-top: 5px;
		border-left: 2px solid #d4af37;
	}

	&-right {
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		line-height: 15px;
		/* or 125% */
		text-align: right;
		/* Neutral / Extra-dark grey */

		color: #293133;
		padding-right: 6px;
		margin-right: 25px;
		margin-top: 5px;
		margin-bottom: 20px;
		border-right: 2px solid #d4af37;
	}
}
.appointments_container-buttons {
	padding-left: 30px;
	&-right {
		&-accept {
			color: #fff;
			padding: 10.5px 19px !important;
			margin: 5px !important;
			border-radius: 8px !important;
		}
		&-deny {
			color: #575e60;
			border: 1px solid #d2d7d9 !important;
			padding: 10.5px 19px !important;

			margin: 5px !important;
			border-radius: 8px !important;
		}
	}
}

.appointments-container-observations {
	font-family: Cabin;
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 19px;

	/* Neutral / Extra-dark grey */

	color: #293133;
	padding-left: 24px;
	padding-top: 20px;
	padding-bottom: 15px;
}
.appointments-container-observations-main {
	background: #ffffff;
	/* Neutral / Medium-light grey */
	width: 650px;
	border: 1px solid #e3e7e8;
	box-sizing: border-box;
	border-radius: 8px;
	margin-left: 24px;
	margin-bottom: 24px;
}
table {
	border-collapse: collapse;
}

tr {
	border-top: 1px solid #e3e7e8;
	cursor: pointer;
}

td {
	height: 5.625rem;
}
.status {
	&-accepted {
		font-family: Roboto;
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 21px;
		/* identical to box height, or 150% */

		/* UI / Green */

		color: #24a54e;
		background: rgba(36, 165, 78, 0.2);
		border-radius: 6px;
		padding: 5px 8px;
	}
	&-denied {
		font-family: Roboto;
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 21px;
		/* identical to box height, or 150% */

		/* UI / Red */

		color: #e8311a;
		background: rgba(232, 49, 26, 0.2);
		border-radius: 6px;
		padding: 5px 8px;
	}

	&-progress {
		font-family: Roboto;
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 21px;
		/* identical to box height, or 150% */

		/* UI / Red */

		color: white;
		background: #2498ff;
		border-radius: 6px;
		padding: 5px 8px;
		width: auto;
	}
}

.loading-circle {
	display: flex;
	justify-content: center;
	text-align: center;
}

.fas.fa-chevron-up,
.fas.fa-chevron-down {
	margin-left: 1rem;
}

.products-catalog {
	background-color: #f3f5f5;
	padding-left: 10%;
	padding-right: 10%;
	padding-top: 40px;
	padding-bottom: 40px;
}

.products-catalog__container {
	background-color: #fff;
	border: 1px solid #e3e7e8;
	border-radius: 8px;
}

.products-catalog__header {
	&-text {
		padding: 1.375rem 0;
		text-align: start;
	}
}

.products-catalog__body-row-image {
	padding: 0 1.25rem;
	text-align: center;

	&--size {
		margin: 0 auto;
		max-width: 7.125rem;
		height: 3.875rem;
		object-fit: cover;
	}
}

.products-catalog__body-row-name {
	&-NEW {
		color: #fff;
		background-color: #e36f05;
		border-radius: 6px;
		padding: 0.125rem 0.375rem;
		font-weight: 500;
		font-size: 0.75rem;
	}

	&-text {
		font-family: Cabin;
		font-weight: bold;
		font-size: 1rem;
		color: #293133;
	}
}

.products-catalog__body-row-sector {
	align-items: center;

	&-text {
		font-size: 1rem;
		color: #575e60;
		width: 15.5rem;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

.products-catalog__body-row-status {
	align-items: center;

	&-text {
		font-size: 50rem;
		color: #575e60;
		width: 50rem;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

.products-catalog__body-row-exhibitor {
	font-size: 1rem;
	color: #575e60;
	padding-right: 30px;
}

.products-catalog__body-row-social {
	&-item {
		padding: 0 0.75rem;
	}
}
.products-catalog__body-row-notext {
	display: flex;
	justify-content: center;
	padding: 8px 10px;
	border: 1px solid #ddd;
	font-size: 14px !important;
	color: #464f63;
	font-weight: 400;
	background-color: #f9f9f9;
}
.products-catalog__body-row-notext:hover {
	background-color: var(--main-10) !important;
}
.products-catalog__body-row-container-titol {
	display: flex;
	justify-content: center;
	font-size: 20px !important;
	color: black;
	font-weight: bold;
	text-align: center;
	margin-bottom: 0.5rem;
}

.products-catalog__body-row-container-text {
	display: flex;
	justify-content: center;
	font-size: 14px !important;
	color: #464f63;
	font-weight: 400;
	text-align: center;
}
.products-catalog__pagination {
	display: flex;
	justify-content: center;
	align-items: baseline;
	padding-top: 1rem;

	&-info {
		margin-right: 0.5rem;
	}

	&-button {
		padding: 0.5rem 0.75rem;
		margin: 0.125rem;
		border-radius: 6px;
		background-color: #f5f7f7;
	}

	&-select {
		width: 4rem;
		margin: 0 0.25rem;
	}
}
.appointments-meeting-link {
	font-family: Cabin;
	font-style: normal;
	color: white;
	text-decoration-line: none;
}
</style>
