<template>
	<div>
		<v-toolbar class="interests-tabs" :style="{ 'background-color': primaryColor }">
			<v-tabs v-model="tabs" dark>
				<v-tab>
					<v-badge color="white" :content="appointments.length ? appointments.length : '0'" inline>
						{{ $t('appointments.appointmentTabAll') }}
					</v-badge>
				</v-tab>
				<v-tab>
					<v-badge color="white" :content="appointmentsAccepted.length ? appointmentsAccepted.length : '0'" inline>
						{{ $t('appointments.appointmentTabAccepted') }}
					</v-badge>
				</v-tab>
				<v-tab>
					<v-badge color="white" :content="appointmentsinProgress.length ? appointmentsinProgress.length : '0'" inline>
						{{ $t('appointments.appointmentTabInProgress') }}
					</v-badge>
				</v-tab>
				<v-tab>
					<v-badge color="white" :content="appointmentsRejected.length ? appointmentsRejected.length : '0'" inline>
						{{ $t('appointments.appointmentTabDenied') }}
					</v-badge>
				</v-tab>

				<!--<v-tab><v-badge color="white" content="5" inline>Actividades</v-badge></v-tab>
				<v-tab><v-badge color="white" content="2" inline>Contactos</v-badge></v-tab>-->
			</v-tabs>
		</v-toolbar>
		<v-tabs-items v-model="tabs">
			<v-tab-item> <AppointmentsTabsAll :appointments="appointments" :user="user" /> </v-tab-item>
			<v-tab-item> <AppointmentsTabsAll :appointments="appointmentsAccepted" :user="user" /></v-tab-item>
			<v-tab-item><AppointmentsTabsAll :appointments="appointmentsinProgress" :user="user" /></v-tab-item>
			<v-tab-item><AppointmentsTabsAll :appointments="appointmentsRejected" :user="user" /></v-tab-item>
		</v-tabs-items>
	</div>
</template>

<script>
import { getDocument } from '@/api/common.js';
import { getUserAppointments } from '@/api/appointments.js';
import AppointmentsTabsAll from '@/components/appointments/AppointmentsTabsAll.vue';

//import ActivitiesInterests from '@/components/interests/ActivitiesInterests.vue';

export default {
	name: 'AppointmentsTabs',
	props: {
		primaryColor: String,
		value: {
			type: [Object, Array]
		}
	},

	components: { AppointmentsTabsAll },

	data() {
		return {
			tabs: null,
			defaultPhoto: null,
			appointments: {},
			appointmentsAccepted: {},
			appointmentsinProgress: {},
			appointmentsRejected: {},
			user: {}
		};
	},
	mounted() {
		this.checkLoginAndGetInterests();
		this.getDefaultPhoto();
	},
	created() {
		this.$puiEvents.$on('reloadAppointment', () => {
			this.checkLoginAndGetInterests();
		});
	},
	destroyed() {
		this.$puiEvents.$off('reloadAppointment');
	},
	methods: {
		checkLoginAndGetInterests() {
			this.checkLoginData();
			if (this.isLogged) {
				// lo ponemos a false para que los cards no existan
				this.user = JSON.parse(localStorage.getItem('pui9_session'));
				console.log(this.user);
				this.getUserAppointments();
				this.getUserAppointmentsAccepted();
				this.getUserAppointmentsInProgress();
				this.getUserAppointmentsRejected();
			}
		},

		getDefaultPhoto: async function () {
			const data = await getDocument('event', this.$store.state.eventInfo.evenid, 'event_app_primary');
			this.defaultPhoto = data[0].url;
		},

		checkLoginData() {
			if (localStorage.getItem('pui9_session') !== null) {
				this.isLogged = true;
			} else {
				this.isLogged = false;
			}
		},
		getUserAppointments: async function () {
			if (this.isLogged) {
				var params = {
					filter: {
						groupOp: 'and',
						groups: [],
						rules: [
							{
								data: this.$store.state.eventInfo.evenid,
								field: 'evenid',
								op: 'eq'
							},

							{
								data: this.user.email, //CORREO DEL USUARIO
								field: 'appovisitorid',
								op: 'eq'
							}
						]
					},

					order: [
						{
							column: '',
							direction: 'asc'
						}
					],
					page: 1,
					queryFields: [''],
					queryText: '',
					rows: 999999
				};

				let data = await getUserAppointments(params);
				this.appointments = data;

				console.log(this.appointments);
			}
		},

		getUserAppointmentsAccepted: async function () {
			if (this.isLogged) {
				var params = {
					filter: {
						groupOp: 'and',
						groups: [],
						rules: [
							{
								data: this.$store.state.eventInfo.evenid,
								field: 'evenid',
								op: 'eq'
							},

							{
								data: this.user.email,
								field: 'appovisitorid',
								op: 'eq'
							},

							{
								data: 'ACCEPTED',
								field: 'appostatus',
								op: 'eq'
							}
						]
					},

					order: [
						{
							column: '',
							direction: 'asc'
						}
					],
					page: 1,
					queryFields: [''],
					queryText: '',
					rows: 999999
				};

				let data = await getUserAppointments(params);
				this.appointmentsAccepted = data;
				console.log(this.appointmentsAccepted);
			}
		},
		getUserAppointmentsInProgress: async function () {
			if (this.isLogged) {
				var params = {
					filter: {
						groupOp: 'and',
						groups: [
							{
								groups: [],
								groupOp: 'or',
								rules: [
									{
										data: 'PENDING_EXHIBITOR',
										field: 'appostatus',
										op: 'eq'
									},

									{
										data: 'PENDING_VISITOR',
										field: 'appostatus',
										op: 'eq'
									}
								]
							}
						],
						rules: [
							{
								data: this.$store.state.eventInfo.evenid,
								field: 'evenid',
								op: 'eq'
							},

							{
								data: this.user.email,
								field: 'appovisitorid',
								op: 'eq'
							}
						]
					},

					order: [
						{
							column: '',
							direction: 'asc'
						}
					],
					page: 1,
					queryFields: [''],
					queryText: '',
					rows: 999999
				};

				let data = await getUserAppointments(params);
				this.appointmentsinProgress = data;
				console.log(this.appointmentsinProgress);
			}
		},
		getUserAppointmentsRejected: async function () {
			if (this.isLogged) {
				var params = {
					filter: {
						groupOp: 'and',
						groups: [],
						rules: [
							{
								data: this.$store.state.eventInfo.evenid,
								field: 'evenid',
								op: 'eq'
							},

							{
								data: this.user.email,
								field: 'appovisitorid',
								op: 'eq'
							},
							{
								data: 'REJECTED',
								field: 'appostatus',
								op: 'eq'
							}
						]
					},

					order: [
						{
							column: '',
							direction: 'asc'
						}
					],
					page: 1,
					queryFields: [''],
					queryText: '',
					rows: 999999
				};

				let data = await getUserAppointments(params);
				this.appointmentsRejected = data;
				console.log(this.appointmentsRejected);
			}
		}
	},
	watch: {
		'$store.state.eventInfo.lang': {
			handler: function () {
				this.getUserAppointments();
				this.getUserAppointmentsAccepted();
				this.getUserAppointmentsInProgress();
				this.getUserAppointmentsRejected();
			},
			deep: true,
			immediate: true
		}
	}
};
</script>


<style lang="scss">
.interests-tabs {
	box-shadow: none !important;
	padding-left: 10%;

	& > .v-toolbar__content > .v-tabs > .v-item-group {
		& > .v-slide-group__prev {
			display: none !important;
		}

		& > .v-slide-group__wrapper > .v-slide-group__content {
			& > .v-tabs-slider-wrapper > .v-tabs-slider {
				border-radius: 8px;
				height: 0.5rem;
			}

			& > .v-tab {
				padding: 0 !important;
				margin-right: 1.5rem !important;
				text-transform: none !important;

				& > .v-badge {
					// font-family: Cabin;

					& > .v-badge__wrapper > .v-badge__badge {
						color: #575e60;
					}
				}
			}
		}
	}
}
</style>
